import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-datavisual',
  templateUrl: './datavisual.component.html',
  styleUrls: ['./datavisual.component.scss']
})
export class DatavisualComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
